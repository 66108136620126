var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "main",
      {
        staticClass: "site-content",
        staticStyle: { "min-height": "0 !important" },
        attrs: { id: "wrapper" }
      },
      [
        _c("div", { staticClass: "site-content__inner" }, [
          _c("div", { staticClass: "site-content__holder" }, [
            _c(
              "figure",
              {
                staticClass: "page-thumbnail page-thumbnail--default",
                staticStyle: { top: "0px" }
              },
              [
                _c("img", {
                  staticClass: "page-bg-logo",
                  attrs: { src: "assets/img/samples/page-bg-logo.png", alt: "" }
                }),
                _c("div", { staticClass: "ncr-page-decor" }, [
                  _c("div", { staticClass: "ncr-page-decor__layer-1" }, [
                    _c("div", { staticClass: "ncr-page-decor__layer-bg" })
                  ]),
                  _c("div", { staticClass: "ncr-page-decor__layer-2" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-3" }, [
                    _c("div", { staticClass: "ncr-page-decor__layer-bg" })
                  ]),
                  _c("div", { staticClass: "ncr-page-decor__layer-4" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-5" }),
                  _c("div", { staticClass: "ncr-page-decor__layer-6" })
                ])
              ]
            ),
            _c("h1", { staticClass: "page-title h3" }, [_vm._v("LEGAL")]),
            _c("div", { staticClass: "page-content" }, [
              _c(
                "div",
                { staticClass: "accordion", attrs: { id: "accordionFaqs" } },
                [
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-item__header",
                        attrs: { id: "headingOne" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-item__link collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseOne",
                              "aria-expanded": "true",
                              "aria-controls": "collapseOne"
                            }
                          },
                          [
                            _vm._v(" How many teams do you currently have? "),
                            _c("span", { staticClass: "accordion-item__icon" })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapseOne",
                          "aria-labelledby": "headingOne",
                          "data-parent": "#accordionFaqs"
                        }
                      },
                      [
                        _c("div", { staticClass: "accordion-item__body" }, [
                          _vm._v(
                            " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-item__header",
                        attrs: { id: "headingTwo" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-item__link",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseTwo",
                              "aria-expanded": "true",
                              "aria-controls": "collapseTwo"
                            }
                          },
                          [
                            _vm._v(" Can I join one of your teams? "),
                            _c("span", { staticClass: "accordion-item__icon" })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse show",
                        attrs: {
                          id: "collapseTwo",
                          "aria-labelledby": "headingTwo",
                          "data-parent": "#accordionFaqs"
                        }
                      },
                      [
                        _c("div", { staticClass: "accordion-item__body" }, [
                          _vm._v(
                            " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-item__header",
                        attrs: { id: "headingThree" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-item__link collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseThree",
                              "aria-expanded": "true",
                              "aria-controls": "collapseThree"
                            }
                          },
                          [
                            _vm._v(
                              " How can I do if I wanna become one of your sponsors? "
                            ),
                            _c("span", { staticClass: "accordion-item__icon" })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapseThree",
                          "aria-labelledby": "headingThree",
                          "data-parent": "#accordionFaqs"
                        }
                      },
                      [
                        _c("div", { staticClass: "accordion-item__body" }, [
                          _vm._v(
                            " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-item__header",
                        attrs: { id: "headingFour" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-item__link collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseFour",
                              "aria-expanded": "true",
                              "aria-controls": "collapseFour"
                            }
                          },
                          [
                            _vm._v(
                              " Do you have plans to create new gaming teams? "
                            ),
                            _c("span", { staticClass: "accordion-item__icon" })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapseFour",
                          "aria-labelledby": "headingFour",
                          "data-parent": "#accordionFaqs"
                        }
                      },
                      [
                        _c("div", { staticClass: "accordion-item__body" }, [
                          _vm._v(
                            " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "accordion-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "accordion-item__header",
                        attrs: { id: "headingFive" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "accordion-item__link collapsed",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseFive",
                              "aria-expanded": "true",
                              "aria-controls": "collapseFive"
                            }
                          },
                          [
                            _vm._v(" How can I order your merchandise? "),
                            _c("span", { staticClass: "accordion-item__icon" })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        attrs: {
                          id: "collapseFive",
                          "aria-labelledby": "headingFive",
                          "data-parent": "#accordionFaqs"
                        }
                      },
                      [
                        _c("div", { staticClass: "accordion-item__body" }, [
                          _vm._v(
                            " Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }